<template>
  <div>

    <div class="commonInputTopBox">
      <pageHeader :pagename="'我的内容贡献'" :line="true"/>
    </div>

    <div class="content" style="margin-left: 60px;margin-top: 50px; margin-bottom: 20px;display: flow-root;">
        <el-col :span="24" style="margin-bottom: 50px;">

            <div>
                <el-radio v-model="showpage" :label="1" border>我的异常问题贡献:{{create_count}}</el-radio>
                <el-radio v-model="showpage" :label="2" border>我的补充或修正贡献:{{update_count}}</el-radio>
            </div>

        </el-col>

        <div v-if="showpage == 1">
        <el-col :span="18" v-for="(data, index) in tableData" :key="data.id+index" style="margin-bottom: 30px;" @click.native="toInfo(data.id)">
            <el-card :body-style="{ padding: '30px 30px 0px 30px' }" shadow="hover">

            <div style="margin-bottom: 20px">
                <div style="font-size: 18px; font-weight: 600;">{{data.abnormal_question}}</div>
            </div>

            <el-form ref="form" :inline="true" label-position="left">

                <el-form-item style="margin-right: 30px;" label="所属类目:">
                    <span>{{ data.question_type.type_name ? data.question_type.type_name:'无关联' }}</span>
                </el-form-item>

                <el-form-item style="margin-right: 30px;" label="软硬类型:">
                    <span>{{ data.types }}</span>
                </el-form-item>

                <el-form-item style="margin-right: 30px;" label="提交人:" v-if="data.author">
                    <span>{{ data.author }}</span>
                </el-form-item>

                <el-form-item style="margin-right: 30px;" label="内容更新次数:">
                    <span>{{ data.update_count }}</span>
                </el-form-item>

                <el-form-item style="margin-right: 30px;" label="适用关键词:" v-if="data.author">
                    <span>{{ data.question_suit }}</span>
                </el-form-item>

            </el-form>

            </el-card>
        </el-col>
        </div>

        <div v-if="showpage == 2">
        <el-col :span="18" v-for="(data, index) in tableData" :key="data.id+index" style="margin-bottom: 30px;" @click.native="toInfo(data.question_id)">
            <el-card :body-style="{ padding: '30px 30px 0px 30px' }" shadow="hover">

            <div style="margin-bottom: 20px">
                <div style="font-size: 18px; font-weight: 600;">{{data.abnormal_question}}</div>
            </div>

            <div style="margin-bottom: 20px">
                <div style="font-size: 14px; font-weight: 600;">更新者: {{data.update_account}} 更新内容: {{data.update_illustrate}}</div>
            </div>

            <el-form ref="form" :inline="true" label-position="left">

                <el-form-item style="margin-right: 30px;" label="更新类型:">
                    <span>{{ data.update_type }}</span>
                </el-form-item>

                <el-form-item style="margin-right: 30px;" label="所属类目:">
                    <span>{{ data.question_type.type_name ? data.question_type.type_name:'无关联' }}</span>
                </el-form-item>

                <el-form-item style="margin-right: 30px;" label="软硬类型:">
                    <span>{{ data.types }}</span>
                </el-form-item>

                <el-form-item style="margin-right: 30px;" label="提交人:" v-if="data.author">
                    <span>{{ data.author }}</span>
                </el-form-item>

                <el-form-item style="margin-right: 30px;" label="内容更新次数:">
                    <span>{{ data.update_count }}</span>
                </el-form-item>

            </el-form>

            </el-card>
        </el-col>
        </div>



    </div>

    <Pagination style="margin-left: 50px" :total="pageCurrent.total" :page.sync="pageCurrent.currentPage" @pagination="search()" :limit.sync="pageCurrent.pageSize" v-if="pageCurrent.total > 0"/>

  </div>
</template>

<script>
// @ is an alias to /src
import { equipmentFaultManageSubmitHistory } from "@/api/manage/operation/equipment_fault_manage.js";
import Pagination from "@/components/Pagination.vue";
export default {
    name: '',
    data() {
        return {
            count: 0,
            tableData: [],
            update_count: 0,
            create_count: 0,
            showpage: 1,
            pageCurrent: {
                currentPage: 1,
                total: 0,
                pageCount: 10,
                pageSize: 10
            },
        };
    },
    created(){
        this.submit_history()
    },
    components: {
        Pagination
    },
    methods:{
        submit_history(){
            equipmentFaultManageSubmitHistory({
                ctype: 1
            }).then((res) => {
                console.log(this.tableData, "new");
                this.tableData = res.data.result;
                this.pageCurrent.currentPage = res.data.currentPage;
                this.pageCurrent.pageCount = res.data.pageCount;
                this.pageCurrent.total = res.data.total;
                this.create_count = res.data.create_count
                this.update_count = res.data.update_count
            });
        },
        update_history(){
            equipmentFaultManageSubmitHistory({
                ctype: 2
            }).then((res) => {
                console.log(this.tableData, "new");
                this.tableData = res.data.result;
                this.pageCurrent.currentPage = res.data.currentPage;
                this.pageCurrent.pageCount = res.data.pageCount;
                this.pageCurrent.total = res.data.total;
                this.create_count = res.data.create_count
                this.update_count = res.data.update_count
            });
        },
        handleButtonClick(val){
            if (val === 1) {
                this.submit_history()
                this.showpage = 1
            }
            if (val === 2) {
                this.update_history()
                this.showpage = 2
            }
        },
        toInfo(id){
            console.log(id);
            this.$router.push({name:'equipmentFaultManage_info', query:{
                id: id
            }});
        }
    }
}
</script>
<style lang="scss" scoped></style>
